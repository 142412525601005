import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';

export default function imgThree_Box(props) {
  const [swiperRef, setSwiperRef] = useState(null);
  let [maxPageCount, setMaxPageCount] = useState(0);
  let [naviCount, setNaviCount] = useState(1);

  let [width, setWidth] = useState(0);
  let [height, setHeight] = useState(0);

  useEffect(() => {
    if (swiperRef != null) {
      swiperRef.slideTo(0, 0); //TAB 영역 누르면 스와이퍼 초기화
    }
    setMaxPageCount(props.data.contents.length / 5);
  }, []);

  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a, b, c, d, e) => {
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '큐레이션 모듈', //모듈 아이디 없으면
        moduleTitle: e, //모듈 제목 (Require)
        moduleSubTitle: '연속이미지형(3개)',
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: props.loc_idx, //페이지에서 해당 모듈의 위치
        moduleItemSeq: c, //모듈 내에서 콘텐츠의 위치
        programId: '', //프로그램의 아이디 (추가)
        programTitle: '', //프로그램의 아이디 (추가)
        contentId: b.link_url, //식별자
        contentTitle: b.title, //콘텐츠의 제목  (Require)
        contentNumber: '',
        contentType: 'LK', //콘텐츠의 타입 (별도 표 참조)
      },
    });
  };

  return (
    <>
      <div className="mainContents-typeWhite">
        <div className="contentsBlock_w">
          <div className="moduleTitleline_w">
            <h2 className="moduleTitleline-Title">{props.data.list_title}</h2>
          </div>
          <div
            className={
              'listModule_w_imgSeriesSquare imgSeriesSquare' + props.loc_idx
            }
          >
            <Swiper
              onSwiper={setSwiperRef}
              slidesPerView={3}
              spaceBetween={4}
              slidesPerGroup={3}
              loop={false}
              pagination={{
                // 페이징 설정
                el: `.imgSeriesSquare${props.loc_idx} .swiper-pagination`,
                clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
              }}
              navigation={{
                nextEl: `.imgSeriesSquare${props.loc_idx} .swiper-button-next`,
                prevEl: `.imgSeriesSquare${props.loc_idx} .swiper-button-prev`,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="listModuleSlider"
              wrapperClass="SliderContent"
            >
              {props.data.contents.map((item, index) => {
                const handleImageLoad = (e) => {
                  const nHeight = e.naturalHeight;
                  const nWidth = e.naturalWidth;
                  const scale = e.naturalWidth / 397;
                  const scaleHeight = nHeight / scale;

                  setHeight(scaleHeight);
                  if (props.forceUpdate) {
                    props.forceUpdate();
                  }
                };

                return (
                  <SwiperSlide>
                    {/* <div className="listModuleItem swiper-slide"> */}
                    <div className="moduleBoxWrap_imgSeriesSquare">
                      <Link href={item.link_url}>
                        <a
                          onClick={(e) =>
                            handleClick(
                              e,
                              item,
                              index,
                              '인기',
                              props.data.list_title
                            )
                          }
                          target={item.link_new_yn == 'Y' ? '_blank' : ''}
                          className="mb_link"
                        >
                          <div className="mb_image_w">
                            <img
                              src={item.img_url}
                              className="mb_image"
                              alt={item.img_attr}
                            />
                            <Image
                              onLoadingComplete={(e) => handleImageLoad(e)}
                              src={item.img_url}
                              className="mb_image"
                              layout="fill"
                              // width={397}
                              // height={height}
                              quality={85}
                              alt={item.img_attr}
                            />
                          </div>
                        </a>
                      </Link>
                    </div>
                    {/* </div> */}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
