import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import {
  toPercentTime,
  toPercentTimeDesc,
  updateQueryStringParameter,
} from '../../../../common/utils/convertUtil';

export default function liveBox(props) {
  const [liveTab, setLiveTab] = useState(0);
  const [swiperRef, setSwiperRef] = useState(null);

  let [maxPageCount, setMaxPageCount] = useState(0);
  let [naviCount, setNaviCount] = useState(1);

  useEffect(() => {
    if (swiperRef != null) {
      swiperRef.slideTo(0, 0); //TAB 영역 누르면 스와이퍼 초기화
    }
    //pc는 한화면에 4개
    if (liveTab == 0) {
      setMaxPageCount(Math.ceil(props.data.contents1.length / 4));
      setNaviCount(1);
    } else {
      setMaxPageCount(Math.ceil(props.data.contents2.length / 4));
      setNaviCount(1);
    }
  }, [liveTab]);
  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a, b, c, d) => {
    let conver_current_s = parseInt(c);
    let conver_current_s2 = parseInt(props.loc_idx);
    let moduleSubTitle;
    let contentTitle;
    let contentId;
    if (d === 'live') {
      moduleSubTitle = '실시간라이브';
      contentTitle = b.title;
      contentId = b.channelid;
    } else if (d === '24') {
      moduleSubTitle = '24t시간TV';
      contentTitle = b.title;
      contentId = b.channelid;
    } else {
      moduleSubTitle = '전체채널';
      contentTitle = '';
      contentId = '';
    }

    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음

    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '라이브', //모듈 아이디 없으면
        moduleTitle: '실시간라이브/24시간TV', //모듈 제목 (Require)
        moduleSubTitle: moduleSubTitle,
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: conver_current_s2, //페이지에서 해당 모듈의 위치
        moduleItemSeq: conver_current_s, //모듈 내에서 콘텐츠의 위치
        programId: '', //프로그램의 아이디 (추가)
        programTitle: contentTitle, //프로그램의 제목 (추가)
        contentId: '라이브', //식별자
        contentTitle: contentTitle, //콘텐츠의 제목  (Require)
        contentType: 'L', //콘텐츠의 타입 (별도 표 참조)
      },
    });
  };

  const handleClick_custom = (a, b, c, d) => {
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음

    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '라이브전체채널',
        clickType: 'NC',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
      },
    });
  };

  // listModuleItem swiper-slide first-item
  return (
    <>
      <div className="mainContents-typeWhite">
        {/* 탭영역 */}
        <div className="contentsBlock_w">
          <div className="moduleTitleline_w">
            <div className="moduleTitleline_linkType">
              <h2 className="moduleTitleline-Title">
                <a
                  href="#none"
                  onClick={() => setLiveTab(0)}
                  className={`moduleTitleline-link${
                    liveTab == 0 ? ' current' : ''
                  }`}
                >
                  실시간 라이브
                </a>
              </h2>
              <span className="moduleTitleline-Dot"></span>
              <h2 className="moduleTitleline-Title">
                <a
                  href="#none"
                  onClick={() => setLiveTab(1)}
                  className={`moduleTitleline-link${
                    liveTab == 1 ? ' current' : ''
                  }`}
                >
                  24시간 TV
                </a>
              </h2>
            </div>
            <a
              href={props.data.alllink_url}
              onClick={(e) => handleClick_custom(e, '', '0', 'all')}
              className="moduleTitleline-linkMore"
              style={{
                visibility:
                  props.data.alllink_yn == 'Y' &&
                  props.data.alllink_url != '' &&
                  props.data.alllink_url != null
                    ? 'visible'
                    : 'hidden',
              }}
            >
              {props.data.alllink_title}
            </a>
          </div>

          {liveTab == 0 ? (
            <>
              <div className={'listModule_w_live live' + props.loc_idx}>
                <button
                  type="button"
                  aria-label="다음 콘텐츠 보기"
                  className="btnTypeList-Next swiper-button-next"
                  onClick={() => setNaviCount(naviCount + 1)}
                >
                  <span className="iconSvg_arrow">
                    <i className="hide">다음</i>
                  </span>
                  <span className="pagingNumber_w">
                    <strong className="pagingNumber-now">{naviCount}</strong>/
                    {maxPageCount}
                  </span>
                </button>
                <Swiper
                  onSwiper={setSwiperRef}
                  slidesPerView={4}
                  spaceBetween={24}
                  slidesPerGroup={4}
                  loop={false}
                  pagination={{
                    // 페이징 설정
                    el: `.live${props.loc_idx} .swiper-pagination`,
                    clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
                  }}
                  navigation={{
                    nextEl: `.live${props.loc_idx} .swiper-button-next`,
                    prevEl: `.live${props.loc_idx} .swiper-button-prev`,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="listModuleSlider"
                  wrapperClass="SliderContent"
                >
                  {props.data.contents1.map((item, index) => {
                    let progressPercent =
                      item.starttime && item.endtime
                        ? toPercentTime(item.starttime, item.endtime)
                        : null;
                    progressPercent =
                      progressPercent > 100 || progressPercent < 0
                        ? null
                        : progressPercent;
                    const progressDesc = progressPercent
                      ? toPercentTimeDesc(
                          item.starttime,
                          item.endtime,
                          progressPercent
                        )
                      : '';

                    const handleOpenPopup = () => {
                      //  485 * 928
                      const popup = window.open(
                        item.talk_link,
                        '타이틀명을 입력하시오',
                        'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no'
                      );
                    };
                    return (
                      <SwiperSlide key={index}>
                        <div
                          className={
                            index == 0
                              ? 'listModuleItem swiper-slide first-item'
                              : 'listModuleItem swiper-slide'
                          }
                        >
                          <div className="moduleBoxWrap_live">
                            <a
                              href={item.url_link}
                              onClick={(e) =>
                                handleClick(e, item, index, 'live')
                              }
                              className="mb_link"
                            >
                              <div className="mb_image_w">
                                <div className="mb_image_inner">
                                  <img
                                    src={item.img_url}
                                    className="mb_image"
                                    alt={item.title || '이미지'}
                                  />
                                </div>
                                <div className="mb_progress_w">
                                  <i className="hide">
                                    라이브 총시간 및 진행시간
                                  </i>
                                  <span
                                    className="mb_progress"
                                    style={{ width: `${progressPercent}%` }}
                                    title={progressDesc}
                                  ></span>
                                </div>
                                <div className="mb_label_w">
                                  {(item.channelid === 'S17' ||
                                    item.channelid === 'S18') && (
                                    <span className="mb_label_bora">보라</span>
                                  )}
                                </div>
                              </div>
                              <div className="mb_info_w">
                                <span className="mbif_title">{item.title}</span>
                                <span className="mbif_subinfo">
                                  {item.sub_title}
                                </span>
                              </div>
                            </a>
                            <div className="moduleBottomBtns">
                              <a
                                href="#none"
                                onClick={handleOpenPopup}
                                className="btnType-playtalk"
                              >
                                <span className="iconSvg_playtalk"></span>
                                <strong className="btnType-title">
                                  플레이톡
                                </strong>
                              </a>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>

                <div className="swiper-pagination"></div>
                <button
                  type="button"
                  aria-label="이전 콘텐츠 보기"
                  className="btnTypeList-Prev swiper-button-prev"
                  onClick={() => setNaviCount(naviCount - 1)}
                >
                  <span className="iconSvg_arrow">
                    <i className="hide">이전</i>
                  </span>
                  <span className="pagingNumber_w">
                    <strong className="pagingNumber-now">{naviCount}</strong>/
                    {maxPageCount}
                  </span>
                </button>
              </div>
            </>
          ) : (
            <>
              <div className={'listModule_w_live live' + props.loc_idx}>
                <button
                  type="button"
                  aria-label="다음 콘텐츠 보기"
                  className="btnTypeList-Next swiper-button-next"
                  onClick={() => setNaviCount(naviCount + 1)}
                >
                  <span className="iconSvg_arrow">
                    <i className="hide">다음</i>
                  </span>
                  <span className="pagingNumber_w">
                    <strong className="pagingNumber-now">{naviCount}</strong>/
                    {maxPageCount}
                  </span>
                </button>
                <Swiper
                  slidesPerView={4}
                  spaceBetween={24}
                  slidesPerGroup={4}
                  loop={false}
                  pagination={{
                    // 페이징 설정
                    el: `.live${props.loc_idx} .swiper-pagination`,
                    clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
                  }}
                  navigation={{
                    nextEl: `.live${props.loc_idx} .swiper-button-next`,
                    prevEl: `.live${props.loc_idx} .swiper-button-prev`,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="listModuleSlider"
                  wrapperClass="SliderContent"
                >
                  {props.data.contents2.map((item, index) => {
                    let progressPercent =
                      item.starttime && item.endtime
                        ? toPercentTime(item.starttime, item.endtime)
                        : null;
                    progressPercent =
                      progressPercent > 100 || progressPercent < 0
                        ? null
                        : progressPercent;
                    const progressDesc = progressPercent
                      ? toPercentTimeDesc(
                          item.starttime,
                          item.endtime,
                          progressPercent
                        )
                      : '';

                    const handleOpenPopup = () => {
                      //  485 * 928
                      const popup = window.open(
                        item.talk_link,
                        '타이틀명을 입력하시오',
                        'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no'
                      );
                    };

                    return (
                      <SwiperSlide key={index}>
                        <div
                          className={
                            index == 0
                              ? 'listModuleItem swiper-slide first-item'
                              : 'listModuleItem swiper-slide'
                          }
                        >
                          <div className="moduleBoxWrap_live">
                            <a
                              href={item.url_link}
                              onClick={(e) => handleClick(e, item, index, '24')}
                              className="mb_link"
                            >
                              <div className="mb_image_w">
                                <div className="mb_image_inner">
                                  <img
                                    src={item.img_url}
                                    className="mb_image"
                                    alt={item.title || '이미지'}
                                  />
                                </div>
                                <div className="mb_progress_w">
                                  <i className="hide">
                                    라이브 총시간 및 진행시간
                                  </i>
                                  <span
                                    className="mb_progress"
                                    style={{ width: `${progressPercent}%` }}
                                    title={progressDesc}
                                  ></span>
                                </div>
                                <div className="mb_label_w">
                                  {(item.channelid === 'S17' ||
                                    item.channelid === 'S18') && (
                                    <span className="mb_label_bora">보라</span>
                                  )}
                                </div>
                              </div>
                              <div className="mb_info_w">
                                <span className="mbif_title">{item.title}</span>
                                <span className="mbif_subinfo">
                                  {item.sub_title}
                                </span>
                              </div>
                            </a>
                            <div className="moduleBottomBtns">
                              <a
                                href="#none"
                                onClick={handleOpenPopup}
                                className="btnType-playtalk"
                              >
                                <span className="iconSvg_playtalk"></span>
                                <strong className="btnType-title">
                                  플레이톡
                                </strong>
                              </a>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>

                <div className="swiper-pagination"></div>
                <button
                  type="button"
                  aria-label="이전 콘텐츠 보기"
                  className="btnTypeList-Prev swiper-button-prev"
                  onClick={() => setNaviCount(naviCount - 1)}
                >
                  <span className="iconSvg_arrow">
                    <i className="hide">이전</i>
                  </span>
                  <span className="pagingNumber_w">
                    <strong className="pagingNumber-now">{naviCount}</strong>/
                    {maxPageCount}
                  </span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
