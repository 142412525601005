import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import ReactSlider from 'react-slick';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';

export default function photostar_Box(props) {
  const [swiperRef, setSwiperRef] = useState(null);
  let [maxPageCount, setMaxPageCount] = useState(0);
  let [naviCount, setNaviCount] = useState(1);

  // let width = 0;
  // let height = 0;
  let [width, setWidth] = useState(0);
  let [height, setHeight] = useState(0);

  const handleImageLoad = (e) => {
    const nHeight = e.naturalHeight;
    const scale = e.naturalWidth / 282;
    const scaleHeight = Math.ceil(nHeight / scale);
    setHeight(scaleHeight);
    if (props.forceUpdate) {
      props.forceUpdate();
    }
  };

  useEffect(() => {
    if (swiperRef != null) {
      swiperRef.slideTo(0, 0); //TAB 영역 누르면 스와이퍼 초기화
    }

    setMaxPageCount(props.data.content2.length / 5);
  }, []);

  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a, b, c, d) => {
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    let moduleId;
    let moduleTitle;
    let contentType;
    if (d === 'a') {
      moduleId = '포토';
      moduleTitle = '현장포토 릴레이';
      contentType = 'PH';
    } else if (d === 'b') {
      moduleId = '게시물';
      moduleTitle = '스브스노리터';
      contentType = 'PO';
    }

    let conver_current_s = parseInt(c);
    let conver_current_s2 = parseInt(props.loc_idx);

    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: moduleId, //모듈 아이디 없으면
        moduleTitle: moduleTitle, //모듈 제목 (Require)
        moduleSubTitle: '',
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: conver_current_s2, //페이지에서 해당 모듈의 위치
        moduleItemSeq: conver_current_s, //모듈 내에서 콘텐츠의 위치
        programId: moduleId, //프로그램의 아이디 (추가)
        programTitle: moduleId, //프로그램의 아이디 (추가)
        contentId: '', //식별자
        contentTitle: b.title, //콘텐츠의 제목  (Require)
        contentNumber: '',
        contentType: contentType, //콘텐츠의 타입 (별도 표 참조)
      },
    });
  };

  const handleClick_custom = (a, b, c, d) => {
    let clickName;
    if (d === 'a') {
      clickName = '스타포토 전체보기';
    } else if (d === 'b') {
      clickName = '스브스노리터 전체보기';
    } else if (d === 'd') {
      clickName = '스타 응원판';
    } else if (d === 'e') {
      clickName = '스타 출석판';
    } else if (d === 'g') {
      clickName = '스타 24시간TV 하단버튼';
    } else {
      clickName = d;
    }

    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: clickName,
        clickType: 'NC',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
      },
    });
  };

  let slickSliderRef = useRef(null);

  const settings = {
    className: 'variable-width', //slider variable-width
    dots: true,
    infinite: true,
    // centerPadding: '25px',
    speed: 300,
    autoplay: false,
    slidesToShow: 4,
    centerMode: true,
    variableWidth: true,
    // customPaging: function (slider, i) {
    //   return (i + 1) + ' <div className="allCount"> / ' + slider.slideCount + '</div>';
    // },

    customPaging: function (i) {
      return (
        <>
          {i + 1}
          <div className="allCount"> / {props.data.content1.length} </div>
        </>
      );
    },
    dotsClass: 'slick-dots notranslate',

    // prevArrow: <button className='slick-prev btn pen_arrow_prev'><span className='iconSvg_penArrowPrev'></span></button>,
    // nextArrow: <button className='slick-next btn pen_arrow_next'><span className='iconSvg_penArrowNext'></span></button>,
  };

  return (
    <>
      <div className="mainContents-typePink">
        {/* <!-- 오늘의 플레이 타이틀 --> */}
        <div className="todayPlay_title">
          <img
            src="/images/img_todayplay.png"
            alt="즐거움이 이어지는 OH! 늘의 플레이"
          />
        </div>

        {/* <!-- 어메이징한 SBS만의 현장 포토 릴레이 --> */}
        <div className="contentsBlock_w todayPlayPhoto_w">
          <div className="moduleTitleline_w">
            <h2 className="moduleTitleline-Title">
              <span className="iconSvg_playPhoto"></span>어메이징한 SBS만의 현장
              포토 릴레이
            </h2>
            <a
              href="https://star.sbs.co.kr/menu_photolist/index.html"
              onClick={(e) => handleClick_custom(e, '', '0', 'a')}
              target="_self"
              className="btnType-roundLinePurple"
            >
              <strong className="btnType-title">스타포토 전체보기</strong>
            </a>
          </div>
          <div className="photoSwiperWrap">
            <div className="swiper mySwiper variable-width">
              <ReactSlider
                ref={(slider) => (slickSliderRef = slider)}
                {...settings}
              >
                {props.data.content1.map((item, index) => {
                  return (
                    <div className="swiper-slide slide-slide">
                      <div className="swiper-cont">
                        <a
                          onClick={(e) => handleClick(e, item, index, 'a')}
                          href={item.img_link}
                          className="todayPhoto_link"
                        >
                          {/* <img src={item.img_url} className="todayPhoto_img" onLoadingComplete={(e) => handleImageLoad(e)} alt={item.img_attr} /> */}
                          <img
                            src={item.img_url}
                            className="todayPhoto_img"
                            alt={item.img_attr}
                          />
                          {/* <Image
                              className="todayPhoto_img"
                              width={282}
                              height={110}
                              layout="fixed"
                              src={item.img_url}
                              alt={item.pc_img_attr}
                            /> */}
                        </a>
                      </div>
                    </div>
                  );
                })}
              </ReactSlider>
              <i className="photoSwiperBg">
                <img src="/images/ct_photo.png" alt="" />
              </i>
            </div>
          </div>

          {/* <div className="photoSwiperWrap">
            <div className="swiper mySwiper variable-width">
              <ReactSlider ref={slider => (slickSliderRef = slider)} {...settings}>
                <div className="swiper-slide slide-slide">
                  <div className="swiper-cont">
                    <a href="#none" className="todayPhoto_link"><img src="https://via.placeholder.com/220x331" className="todayPhoto_img" alt="" /></a>
                  </div>
                </div>

              </ReactSlider>
            </div>

            <div className="slick-dots"></div>

            <i className="photoSwiperBg"><img src="../image/ct_photo.png" alt="" /></i>

          </div> */}
        </div>

        {/* <div className="contentsBlock_w todayPlayPhoto_w">
          <div className="moduleTitleline_w">
            <h2 className="moduleTitleline-Title"><span className="iconSvg_playPhoto"></span>{props.data.subtitle1}</h2>
            <a href="https://star.sbs.co.kr/menu_photolist/index.html" className="btnType-roundLinePurple"><strong className="btnType-title">스타포토 전체보기</strong></a>
          </div>

          <div className="photoSwiperWrap">
            <div className="swiper mySwiper variable-width">

              <ReactSlider ref={slider => (slickSliderRef = slider)} {...settings}>
                {
                  props.data.content1.map((item, index) => {
                    const handleImageLoad = (e) => {
                      const nHeight = e.naturalHeight;
                      const nWidth = e.naturalWidth;
                      const scale = e.naturalWidth / 282;
                      const scaleHeight = nHeight / scale;
                      setHeight(scaleHeight);
                      if (props.forceUpdate) {
                        props.forceUpdate();
                      }
                    };

                    return (
                      <div className="swiper-slide slide-slide" >
                        <div className="swiper-cont" >
                          <a href={item.img_link} className="todayPhoto_link">
                            <Image
                              onLoadingComplete={(e) => handleImageLoad(e)}
                              className="todayPhoto_img"
                              width={282}
                              height={height}
                              layout="fixed"
                              src={item.img_url}
                              alt={item.pc_img_attr}
                            />
                          </a>
                        </div>
                      </div>
                    );
                  })
                }

              </ReactSlider>

            </div>

            <div className="slick-dots"></div>
            <i className="photoSwiperBg"><img src="/images/ct_photo.png" alt="" /></i>
          </div>
        </div> */}

        {/* <!-- 재미있는 방송이야기 스브스노리터 --> */}
        {/*<div className="contentsBlock_w playground_w">
          <div className="moduleTitleline_w">
            <h2 className="moduleTitleline-Title"><i className="iconSvg_playPhoto"></i>{props.data.subtitle2}</h2>
            <a href="https://programs.sbs.co.kr/special/pdnote/multiboards/65942" onClick={(e) => handleClick_custom(e, '', '0', 'b')} className="btnType-roundLinePurple"><strong className="btnType-title">스브스노리터 전체보기</strong></a>
          </div>
          <div
            className={
              'listModule_w_sbsPlayGround sbsPlayGround' + props.loc_idx
            }
          >
            <button
              type="button"
              className="btnLineTypeList-Next swiper-button-next"
            >
              <span className="iconSvg_arrow">
                <i className="hide">다음</i>
              </span>
            </button>

            <Swiper
              onSwiper={setSwiperRef}
              slidesPerView={5}
              spaceBetween={25}
              slidesPerGroup={5}
              loop={false}
              pagination={{
                // 페이징 설정
                el: `.sbsPlayGround${props.loc_idx} .swiper-pagination`,
                clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
              }}
              navigation={{
                nextEl: `.sbsPlayGround${props.loc_idx} .swiper-button-next`,
                prevEl: `.sbsPlayGround${props.loc_idx} .swiper-button-prev`,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="listModuleSlider"
              wrapperClass="SliderContent"
            >
              {props.data.content2.map((item, index) => {
                const handleOpenPopup = () => {
                  //  485 * 928
                  const popup = window.open(
                    item.talk_link,
                    '타이틀명을 입력하시오',
                    'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no'
                  );
                };
                return (
                  <SwiperSlide>
                    <div className="listModuleItem swiper-slide">
                      <div className="moduleBoxWrap_playGround">
                        <a
                          onClick={(e) => handleClick(e, item, index, 'b')}
                          href={item.img_link}
                          className="mb_link"
                        >
                          <div className="mb_image_w">
                            <div className="mb_image_inner">
                              <img
                                src={item.img_url}
                                className="mb_image"
                                alt={item.img_attr}
                              />
                            </div>
                          </div>
                          <div className="mb_info_w">
                            <span className="mbif_title">{item.title}</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div className="swiper-pagination"></div>
            <button
              type="button"
              className="btnLineTypeList-Prev swiper-button-prev"
            >
              <span className="iconSvg_arrow">
                <i className="hide">이전</i>
              </span>
            </button>
          </div>
            </div>*/}

        {/* <!-- SBS만의 특별한 스타서비스 --> */}
        <div className="contentsBlock_w starService_w">
          <div className="moduleTitleline_w">
            <h2 className="moduleTitleline-Title">
              <i className="iconSvg_playPhoto"></i>
              {props.data.subtitle3}
            </h2>
            <a
              href="https://star.sbs.co.kr"
              onClick={(e) => handleClick_custom(e, '', '0', 'c')}
              className="btnType-roundLinePurple"
            >
              <strong className="btnType-title">스타서비스 전체보기</strong>
            </a>
          </div>
          <div className="listModule_w_starService">
            {/* <!-- mySwiper 클래스 추가시 동작 --> */}
            <div className="listModuleSlider">
              {/* <!-- 리스트 --> */}
              <div className="SliderContent swiper-wrapper">
                <div className="listModuleItem swiper-slide">
                  <div className="moduleStarBoxWrap_type1">
                    <a
                      onClick={(e) => handleClick_custom(e, '', '0', 'd')}
                      href="https://star.sbs.co.kr/menu_fanboard/index.html"
                      className="msb_link"
                    >
                      <strong className="msb_title">응원판</strong>
                      <p className="msb_text">당신의 스타를 응원하세요~♡</p>
                      <span className="icon_starCheer">
                        <img
                          src="/images/icon_starcheer.png"
                          className="image_starCheer"
                          alt=""
                        />
                      </span>
                      <div className="msb_bestTalk_w">
                        <em className="msb_bestTalk_title">BEST 톡</em>
                        <p className="msb_bestTalk_text">
                          {props.data.content3[0].cheer_comment}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="listModuleItem swiper-slide">
                  <div className="moduleStarBoxWrap_type2">
                    <a
                      onClick={(e) => handleClick_custom(e, '', '0', 'e')}
                      href="https://star.sbs.co.kr/menu_stamp/index.html"
                      className="msb_link"
                    >
                      <strong className="msb_title">출석판</strong>
                      <p className="msb_text">매일매일 포인트 선물받기!</p>
                      <span className="icon_starAttend">
                        <img
                          src="/images/icon_starattend.png"
                          className="image_starAttend"
                          alt=""
                        />
                      </span>
                    </a>
                  </div>
                </div>

                <div className="listModuleItem swiper-slide">
                  <div className="moduleStarBoxWrap_type1">
                    <Link href={props.data.content3[0].star24_icon_link}>
                      <a
                        onClick={(e) =>
                          handleClick_custom(
                            e,
                            '',
                            '0',
                            props.data.content3[0].star24_button_nm
                          )
                        }
                        target={
                          props.data.content3[0].star24_icon_link_new_yn == 'Y'
                            ? '_blank'
                            : ''
                        }
                        className="msb_link"
                      >
                        <strong className="msb_title">
                          {props.data.content3[0].star24_title}
                        </strong>
                        <p className="msb_text">
                          {props.data.content3[0].star24_sub_title}
                        </p>
                        <span className="icon_star24TV">
                          <img
                            src="/images/icon_star24TV.png"
                            className="image_star24tv"
                            alt=""
                          />
                        </span>
                      </a>
                    </Link>
                    <Link href={props.data.content3[0].star24_button_link}>
                      <a
                        onClick={(e) =>
                          handleClick_custom(
                            e,
                            '',
                            '0',
                            props.data.content3[0].star24_button_nm
                          )
                        }
                        target={
                          props.data.content3[0].star24_button_link_new_yn ==
                            'Y'
                            ? '_blank'
                            : ''
                        }
                        className="btnType-roundPurple"
                      >
                        <strong className="btnType-title">
                          {props.data.content3[0].star24_button_nm}
                        </strong>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className="todayPlayBg-left">
          <img
            src="/images/ct_playground.gif"
            className="todayPlayBg-img"
            alt=""
          />
        </span>
        <span className="todayPlayBg-right">
          <img
            src="/images/ct_star_fan.png"
            className="todayPlayBg-img"
            alt=""
          />
        </span>
      </div>
    </>
  );
}
